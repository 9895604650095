<template>
  <default-layout>
    <portal to="sidebarContent">
      <div class="sidebar-left__content-header">
        {{ $t('app.navigation') }}
      </div>
      <div class="sidebar-left__menu">
        <ul>
          <li>
            <router-button
              routeName="SettingsCompany"
              :text="$t('navigation.company')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="SettingsBrand"
              :text="$t('navigation.brand')"
            ></router-button>
          </li>
          <li>
            <router-button
              routeName="SettingsUsers"
              :text="$t('company.users')"
            ></router-button>
          </li>
        </ul>
      </div>
    </portal>
    <div>
      <vue-page-transition name="fade">
        <router-view></router-view>
      </vue-page-transition>
    </div>
  </default-layout>
</template>

<script>
export default {
  name: 'SettingsLayout'
}
</script>
